
import { Component, Vue, Prop } from "vue-property-decorator";
import { ROUTES, UserRole } from "@/data";
import { Action } from "vuex-class";
import { StoreAction } from "@/types";
import { formatErrors } from "@/helpers/commons";

export interface ILoginForm {
    username?: string;
    email?: string;
    password?: string;
}

@Component({
    name: "Login",
})
export default class Login extends Vue {
    view: string = "login";
    errors: any = {};
    globalErrors: any = {};

    loading = false;

    @Action("auth/login") login!: StoreAction;
    @Action("actions/getProfile") getProfile!: StoreAction;
    @Action("auth/forgotPassword") forgotPassword!: StoreAction;

    showForgotForm() {
        this.errors = {};
        this.view = "forgot";
    }

    showLoginForm() {
        this.errors = {};
        this.view = "login";
    }

    loginForm: ILoginForm = {
        username: "",
        password: "",
    };

    forgotForm: ILoginForm = {
        username: "",
    };

    goToRegister() {
        this.$router.push({ name: ROUTES.REGISTER });
    }

    async signin() {
        this.loading = true;
        this.errors = {};
        this.globalErrors = {};
        try {
            await this.login(this.loginForm);
            const currentProfile = await this.getProfile();

            if ([UserRole.Interim, UserRole.Company].includes(currentProfile.role)) {
                this.errors.password = {
                    id: "Auth.Role.Unauthorized",
                };

                throw [{ id: "Auth.Role.Unauthorized" }];
            }

            await this.$router.push({ name: ROUTES.APP._ROOT });
        } catch (e) {
            const errors = formatErrors(e);
            this.errors = errors.errors;
            this.globalErrors = errors._global;
        }
        this.loading = false;
    }

    async forgot() {
        this.loading = true;
        this.errors = {};
        this.globalErrors = {};
        try {
            if (this.forgotForm.username) {
                await this.forgotPassword(this.forgotForm);
                this.view = "successForgot";
            } else {
                this.errors.username = { id: "Validation.Any.Empty" };
            }
        } catch (e) {
            const errors = formatErrors(e);
            this.errors = errors.errors;
            this.errors.username = { id: "Auth.Username.NotFound" };
            // @ts-ignore
            this.globalErrors = e._global;
        }
        this.loading = false;
    }
}
